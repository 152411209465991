import React, { Component } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { isAnyFilterActive, findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import {
  SearchResultsPanel,
  SearchFiltersMobile,
  SearchFiltersPrimary,
  SearchFiltersSecondary,
  SortBy,
  Button,
} from '../../components';
import Select from 'react-select';

import { TopbarSearchForm } from '../../forms';

import FilterComponent from './FilterComponent';
import { validFilterParams } from './SearchPage.helpers';

import css from './SearchPage.module.css';
import { BiSearch, BiArrowBack } from 'react-icons/bi';

import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { parse } from '../../util/urlHelpers';

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

/**
 * MainPanel contains search results and filters.
 * There are 3 presentational container-components that show filters:
 * SearchfiltersMobile, SearchFiltersPrimary, and SearchFiltersSecondary.
 * The last 2 are for desktop layout.
 */
class MainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecondaryFiltersOpen: false,
      currentQueryParams: props.urlQueryParams,
      minAge: '',
      maxAge: '',
      county: [],
      countySelect: [],
    };
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.changeQueryParams = props.urlQueryParams;
    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }
  onChange(location) {
    if (location.selectedPlace) {
      this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, urlQueryParams, sortConfig, filterConfig } = this.props;
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { urlQueryParams, history, filterConfig } = this.props;
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  initialValues(queryParamNames) {
    // Query parameters that are visible in the URL
    const urlQueryParams = this.props.urlQueryParams;
    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { urlQueryParams, history, sortConfig, filterConfig } = this.props;
    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        return {
          currentQueryParams: { ...mergedQueryParams, ...updatedURLParams, address, bounds },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);

          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, urlQueryParams } = this.props;
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }
  handleKeyPress = event => {
    if (event.key === 'Enter') {
    }
  };
  componentDidUpdate(prevProps, prevState) {
    const { urlQueryParams, filterConfig } = this.props;
    const countyOption = findOptionsForSelectFilter('county', filterConfig);
    const selectedCounty = urlQueryParams.hasOwnProperty('pub_county')
      ? urlQueryParams.pub_county.includes('hasAny') || urlQueryParams.pub_county.includes('hasAll')
        ? urlQueryParams.pub_county.split(':')[1].split(',')
        : urlQueryParams.pub_county
      : null;

    const shouldUpdate = !isEqual(
      selectedCounty?.split(',') || [],
      this.state.countySelect.map(c => c.value) || []
    );
    console.log(
      2222,
      selectedCounty?.split(','),
      this.state.countySelect.map(c => c.value)
    );
    const dfltValues = countyOption.filter(c => selectedCounty?.includes(c.value));
    if (shouldUpdate) {
      this.setState({ countySelect: dfltValues });
    }
  }
  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      listings,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      onMapIconClick,
      pagination,
      searchParamsForPagination,
      showAsModalMaxWidth,
      filterConfig,
      sortConfig,
      history,
      isMobileModalOpen,
      isAuthenticated,
      currentUser,
      width,
      location,
    } = this.props;
    const search = parse(location.search);
    const countyOption = findOptionsForSelectFilter('county', filterConfig);
    const allSubCategories = findOptionsForSelectFilter('subCategories', filterConfig);

    const selectedActivities = urlQueryParams?.pub_categories?.split(':')[1].split(',');

    const primaryFilters = filterConfig.filter(
      f =>
        f.group === 'primary' &&
        // f.id === 'county' ||
        (f.id === 'categories' ||
          f.id === 'subCategories' ||
          f.id === 'dates-length' ||
          f.id === 'age')
    );
    const secondaryFilters = filterConfig.filter(f => f.group !== 'primary');
    const hasSecondaryFilters = !!(secondaryFilters && secondaryFilters.length > 0);

    // Selected aka active filters
    const selectedFilters = validFilterParams(urlQueryParams, filterConfig);
    const selectedFiltersCount = Object.keys(selectedFilters).length;

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(urlQueryParams, secondaryFilters)
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const format = (selectedOptions, queryParamName, searchMode) => {
      const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
      const mode = searchMode ? `${searchMode}:` : '';
      const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : selectedOptions;
      return { [queryParamName]: value };
    };
    const handleChange = (e, selectedOptions, queryParamName) => {
      this.changeQueryParams[queryParamName] = format(selectedOptions, queryParamName)[
        queryParamName
      ];
      this.setState({
        currentQueryParams: { ...this.state.currentQueryParams, ...this.changeQueryParams },
      });
      this.props.history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, this.changeQueryParams)
      );
    };
    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
          isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
          toggleSecondaryFiltersOpen: isOpen => {
            this.setState({ isSecondaryFiltersOpen: isOpen });
          },
          selectedSecondaryFiltersCount,
        }
      : {};

    // With time-based availability filtering, pagination is NOT
    // supported. In these cases we get the pagination support info in
    // the response meta object, and we can use the count of listings
    // as the result count.
    //
    // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
    const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    const listingsLength = listings ? listings.length : 0;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;

    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;

    const sortBy = mode => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        urlQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
              rootClassName: css.sortBy,
              menuLabelRootClassName: css.sortByMenuLabel,
            }
          : {};
      return sortConfig.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sort={urlQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          onSelect={this.handleSortBy}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };
    const selectedCounty = urlQueryParams.hasOwnProperty('pub_county')
      ? urlQueryParams.pub_county.includes('hasAny') || urlQueryParams.pub_county.includes('hasAll')
        ? urlQueryParams.pub_county.split(':')[1].split(',')
        : urlQueryParams.pub_county
      : null;

    const categories = urlQueryParams.hasOwnProperty('pub_categories')
      ? urlQueryParams.pub_categories
      : [];

    //for multi select countyReactMultiSelectCheckboxes
    const dfltValues = countyOption.filter(c => selectedCounty?.includes(c.value));

    // for single select countyReactMultiSelectCheckboxes

    const classes = classNames(rootClassName || css.searchResultContainer, className);
    const campSubs = categories.includes('camps')
      ? allSubCategories?.filter(
          s =>
            s.value === 'creative' ||
            s.value === 'dance' ||
            s.value === 'food' ||
            s.value === 'educational' ||
            s.value === 'inclusionAndSpecialNeeds' ||
            s.value === 'languages' ||
            s.value === 'multiActivity' ||
            s.value === 'music' ||
            s.value === 'outdoor' ||
            s.value === 'performingArts' ||
            s.value === 'residential' ||
            s.value === 'sports' ||
            s.value === 'STEM' ||
            s.value === 'wellbeing' ||
            s.value === 'other'
        )
      : [];
    const classesSubs = categories.includes('classes')
      ? allSubCategories?.filter(
          s =>
            s.value === 'creative' ||
            s.value === 'dance' ||
            s.value === 'food' ||
            s.value === 'educational' ||
            s.value === 'inclusionAndSpecialNeeds' ||
            s.value === 'languages' ||
            s.value === 'multiActivity' ||
            s.value === 'music' ||
            s.value === 'outdoor' ||
            s.value === 'performingArts' ||
            s.value === 'sports' ||
            s.value === 'STEM' ||
            s.value === 'wellbeing' ||
            s.value === 'other'
        )
      : [];
    const clubSubs = categories.includes('club')
      ? allSubCategories?.filter(
          s =>
            // s.value === 'business' ||
            s.value === 'creative' ||
            s.value === 'cultural' ||
            s.value === 'food' ||
            s.value === 'educational' ||
            s.value === 'languages' ||
            // s.value === 'literature' ||
            s.value === 'music' ||
            s.value === 'outdoor' ||
            s.value === 'performingArts' ||
            s.value === 'sports' ||
            s.value === 'STEM' ||
            s.value === 'wellbeing' ||
            s.value === 'writing' ||
            s.value === 'other'
        )
      : [];
    const eventsSubs = categories.includes('events')
      ? allSubCategories?.filter(
          s =>
            s.value === 'creative' ||
            s.value === 'cultural' ||
            s.value === 'dance' ||
            s.value === 'family' ||
            s.value === 'festival' ||
            s.value === 'music' ||
            s.value === 'nature' ||
            s.value === 'performingArts' ||
            s.value === 'sports' ||
            s.value === 'other'
        )
      : [];
    const inSchlProgSubs = categories.includes('inSchoolProgrammes')
      ? allSubCategories?.filter(
          s =>
            // s.value === 'business' ||
            s.value === 'creative' ||
            s.value === 'cultural' ||
            s.value === 'dance' ||
            s.value === 'educational' ||
            s.value === 'languages' ||
            // s.value === 'math' ||
            s.value === 'music' ||
            s.value === 'performingArts' ||
            s.value === 'sports' ||
            s.value === 'STEM' ||
            s.value === 'wellbeing' ||
            s.value === 'other'
        )
      : [];
    const workshopSubs = categories.includes('workshops')
      ? allSubCategories?.filter(
          s =>
            s.value === 'creative' ||
            s.value === 'dance' ||
            s.value === 'food' ||
            s.value === 'educational' ||
            s.value === 'inclusionAndSpecialNeeds' ||
            s.value === 'languages' ||
            s.value === 'multiActivity' ||
            s.value === 'music' ||
            s.value === 'outdoor' ||
            s.value === 'performingArts' ||
            s.value === 'sports' ||
            s.value === 'STEM' ||
            s.value === 'wellbeing' ||
            s.value === 'other'
        )
      : [];
    // const subCategoryOptions =
    //   categories[0] === 'camps'
    //     ? campSubs
    //     : categories[0] === 'classes'
    //     ? classesSubs
    //     : categories[0] === 'club'
    //     ? clubSubs
    //     : categories[0] === 'events'
    //     ? eventsSubs
    //     : categories[0] === 'inSchoolProgrammes'
    //     ? inSchlProgSubs
    //     : categories[0] === 'workshops'
    //     ? workshopSubs
    //     : [];
    const subCategoryConfig =
      categories.includes('camps') ||
      categories.includes('classes') ||
      categories.includes('club') ||
      categories.includes('events') ||
      categories.includes('inSchoolProgrammes') ||
      categories.includes('workshops')
        ? {
            ...primaryFilters.find(o => o.id === 'subCategories'),
            config: {
              ...primaryFilters.find(o => o.id === 'subCategories').config,
              options: [
                ...campSubs,
                ...classesSubs,
                ...clubSubs,
                ...eventsSubs,
                ...inSchlProgSubs,
                ...workshopSubs,
              ],
            },
          }
        : null;
    const handleSelectChange = e => {
      const newSearch = { ...search, pub_county: e.map(f => f.value).join(',') };
      this.props.history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, newSearch)
      );
    };
    return (
      <>
        {width > 767 ? (
          <div className={css.fliterppup}>
            <div className={css.filterPrimary}>
              <div className={css.srchSelect}>
                <ReactMultiSelectCheckboxes
                  placeholderButtonLabel="Select counties"
                  isMulti
                  isClearable={true}
                  placeholder={<div>Select County</div>}
                  options={countyOption}
                  defaultValue={this.state.countySelect}
                  value={this.state.countySelect}
                  onChange={handleSelectChange}
                />
              </div>
              <SearchFiltersPrimary
                className={css.searchFiltersPrimary}
                sortByComponent={sortBy('desktop')}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                {...propsForSecondaryFiltersToggle}
              >
                <span> Search By: </span>

                <FilterComponent
                  className={css.btnact}
                  // key={`SearchFiltersPrimary.${config.id}`}
                  key={primaryFilters.filter(o => o.id === 'categories')[0].id}
                  idPrefix="SearchFiltersPrimary"
                  filterConfig={primaryFilters.filter(o => o.id === 'categories')[0]}
                  urlQueryParams={urlQueryParams}
                  initialValues={this.initialValues}
                  getHandleChangedValueFn={this.getHandleChangedValueFn}
                  showAsPopup
                  contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                />
                {/* {subCategoryConfig && (
                  <FilterComponent
                    className={css.catact}
                    // key={`SearchFiltersPrimary.${config.id}`}
                    key={primaryFilters.filter(o => o.id === 'subCategories')[0].id}
                    idPrefix="SearchFiltersPrimary"
                    filterConfig={subCategoryConfig}
                    urlQueryParams={urlQueryParams}
                    initialValues={this.initialValues}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    showAsPopup
                    contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                  />
                )} */}
                <FilterComponent
                  key={primaryFilters.filter(o => o.id === 'dates-length')[0].id}
                  idPrefix="SearchFiltersPrimary"
                  filterConfig={primaryFilters.filter(o => o.id === 'dates-length')[0]}
                  urlQueryParams={urlQueryParams}
                  initialValues={this.initialValues}
                  getHandleChangedValueFn={this.getHandleChangedValueFn}
                  showAsPopup
                  contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                />
                <FilterComponent
                  className={css.agsact}
                  // key={`SearchFiltersPrimary.${config.id}`}
                  key={primaryFilters.filter(o => o.id === 'age')[0].id}
                  idPrefix="SearchFiltersPrimary"
                  filterConfig={primaryFilters.filter(o => o.id === 'age')[0]}
                  urlQueryParams={urlQueryParams}
                  initialValues={this.initialValues}
                  getHandleChangedValueFn={this.getHandleChangedValueFn}
                  showAsPopup
                  contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                />

                <Button
                  className={css.clearAll}
                  type="submit"
                  onClick={() => {
                    this.setState({ minAge: '', maxAge: '', currentQueryParams: '' });
                    Object.keys(urlQueryParams).map(k => delete urlQueryParams[k]);
                    history.push(
                      createResourceLocatorString(
                        'SearchPage',
                        routeConfiguration(),
                        {},
                        urlQueryParams
                      )
                    );
                  }}
                >
                  Clear All Filters
                </Button>
              </SearchFiltersPrimary>
            </div>
          </div>
        ) : null}
        <div className={css.ageContainer}>
          {true ? (
            <div className={css.searchResultSummary}>
              {width > 767 ? (
                <>
                  <button className={css.backbtn} onClick={() => history.goBack()}>
                    <BiArrowBack /> Back
                  </button>
                  <span className={css.resultsFound}>
                    <FormattedMessage
                      id="SearchFiltersPrimary.foundResults"
                      values={{ count: totalItems }}
                    />
                  </span>
                </>
              ) : (
                <div className={css.bcktldv}>
                  <button className={css.backbtn} onClick={() => history.goBack()}>
                    <BiArrowBack /> Back
                  </button>
                  <span className={css.resultsFound}>
                    <FormattedMessage
                      id="SearchFiltersPrimary.foundResults"
                      values={{ count: totalItems }}
                    />
                  </span>
                </div>
              )}

              <div className={css.selectedFilters}>
                {selectedActivities?.map((i, index) => (
                  <div className={css.selectedFilter} key={index}>
                    {i.charAt(0).toUpperCase() + i.slice(1)}
                    {selectedActivities.length - 1 === index ? '' : ','}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        <div className={`${classes} ${css.srchpageup}`}>
          <div className={css.cstfilt}>
            {' '}
            {width <= 767 ? (
              <div className={css.fliterppup}>
                <div className={css.filterPrimary}>
                  <div className={css.srchSelect}>
                    <ReactMultiSelectCheckboxes
                      placeholderButtonLabel="Choose county"
                      isMulti
                      isClearable={true}
                      placeholder={<div>Select County</div>}
                      options={countyOption}
                      defaultValue={this.state.countySelect}
                      value={this.state.countySelect}
                      onChange={handleSelectChange}
                    />
                  </div>
                  <SearchFiltersPrimary
                    className={css.searchFiltersPrimary}
                    sortByComponent={sortBy('desktop')}
                    listingsAreLoaded={listingsAreLoaded}
                    resultsCount={totalItems}
                    searchInProgress={searchInProgress}
                    searchListingsError={searchListingsError}
                    {...propsForSecondaryFiltersToggle}
                  >
                    <span> Search By: </span>

                    <FilterComponent
                      className={css.btnact}
                      // key={`SearchFiltersPrimary.${config.id}`}
                      key={primaryFilters.filter(o => o.id === 'categories')[0].id}
                      idPrefix="SearchFiltersPrimary"
                      filterConfig={primaryFilters.filter(o => o.id === 'categories')[0]}
                      urlQueryParams={urlQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      showAsPopup
                      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                    />

                    {subCategoryConfig && (
                      <FilterComponent
                        className={css.catact}
                        // key={`SearchFiltersPrimary.${config.id}`}
                        key={primaryFilters.filter(o => o.id === 'subCategories')[0].id}
                        idPrefix="SearchFiltersPrimary"
                        filterConfig={subCategoryConfig}
                        urlQueryParams={urlQueryParams}
                        initialValues={this.initialValues}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        showAsPopup
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                      />
                    )}
                    <FilterComponent
                      key={primaryFilters.filter(o => o.id === 'dates-length')[0].id}
                      idPrefix="SearchFiltersPrimary"
                      filterConfig={primaryFilters.filter(o => o.id === 'dates-length')[0]}
                      urlQueryParams={urlQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      showAsPopup
                      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                    />
                    <FilterComponent
                      className={css.agsact}
                      // key={`SearchFiltersPrimary.${config.id}`}
                      key={primaryFilters.filter(o => o.id === 'age')[0].id}
                      idPrefix="SearchFiltersPrimary"
                      filterConfig={primaryFilters.filter(o => o.id === 'age')[0]}
                      urlQueryParams={urlQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      showAsPopup
                      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                    />

                    <Button
                      className={css.clearAll}
                      type="submit"
                      onClick={() => {
                        this.setState({ minAge: '', maxAge: '', currentQueryParams: '' });
                        Object.keys(urlQueryParams).map(k => delete urlQueryParams[k]);
                        history.push(
                          createResourceLocatorString(
                            'SearchPage',
                            routeConfiguration(),
                            {},
                            urlQueryParams
                          )
                        );
                      }}
                    >
                      Clear All Filters
                    </Button>
                  </SearchFiltersPrimary>
                </div>
              </div>
            ) : null}
            <SearchFiltersMobile
              className={css.searchFiltersMobile}
              urlQueryParams={urlQueryParams}
              sortByComponent={sortBy('mobile')}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              showAsModalMaxWidth={showAsModalMaxWidth}
              onMapIconClick={onMapIconClick}
              onManageDisableScrolling={onManageDisableScrolling}
              onOpenModal={onOpenModal}
              onCloseModal={onCloseModal}
              resetAll={this.resetAll}
              selectedFiltersCount={selectedFiltersCount}
            >
              {filterConfig
                .filter(f => f.id === 'age' || f.id === 'categories' || f.id === 'dates-length')
                .map(config => {
                  return config.id === 'subCategories' &&
                    (categories.includes('camps') ||
                      categories.includes('classes') ||
                      categories.includes('club') ||
                      categories.includes('events') ||
                      categories.includes('inSchoolProgrammes') ||
                      categories.includes('workshops')) ? (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config.id}`}
                      idPrefix="SearchFiltersMobile"
                      filterConfig={subCategoryConfig}
                      urlQueryParams={urlQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      liveEdit
                      showAsPopup={false}
                    />
                  ) : (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config.id}`}
                      idPrefix="SearchFiltersMobile"
                      filterConfig={config}
                      urlQueryParams={urlQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      liveEdit
                      showAsPopup={false}
                    />
                  );
                })}
            </SearchFiltersMobile>
          </div>

          {isSecondaryFiltersOpen ? (
            <div className={classNames(css.searchFiltersPanel)}>
              <SearchFiltersSecondary
                urlQueryParams={urlQueryParams}
                listingsAreLoaded={listingsAreLoaded}
                applyFilters={this.applyFilters}
                cancelFilters={this.cancelFilters}
                resetAll={this.resetAll}
                onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
              >
                {secondaryFilters.map(config => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersSecondary.${config.id}`}
                      idPrefix="SearchFiltersSecondary"
                      filterConfig={config}
                      urlQueryParams={urlQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      showAsPopup={false}
                    />
                  );
                })}
              </SearchFiltersSecondary>
            </div>
          ) : (
            <div
              className={classNames(css.listings, {
                [css.newSearchInProgress]: !listingsAreLoaded,
              })}
            >
              {searchListingsError ? (
                <h2 className={css.error}>
                  <FormattedMessage id="SearchPage.searchError" />
                </h2>
              ) : null}
              <SearchResultsPanel
                className={css.searchListingsPanel}
                listings={listings}
                pagination={listingsAreLoaded ? pagination : null}
                search={searchParamsForPagination}
                setActiveListing={onActivateListing}
                history={history}
                currentUser={currentUser}
                isAuthenticated={isAuthenticated}
                isMobileModalOpen={isMobileModalOpen}
                urlQueryParams={urlQueryParams}
                location={this.props.location}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  resultsCount: null,

  listings: [],
  // resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,
  // listingsAreLoaded: bool.isRequired,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default MainPanel;
